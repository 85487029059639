export const breakpoints = {
  640: {
    slidesPerView: 3,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: 5,
    spaceBetween: 0,
  },
  1280: {
    slidesPerView: 5,
    spaceBetween: 100,
  },
  1500: {
    slidesPerView: 7,
    spaceBetween: 50,
  },
  1900: {
    slidesPerView: 7,
    spaceBetween: 100,
  },
};
