import React from 'react';

const Cart = () => {
  return (
    <>
      <h3>All you need for your family's sandwiches</h3>
      <div></div>
    </>
  );
};

export default Cart;
