export const SANDWICH_IMAGES_PATH = 'uploads/sandwiches/';

export const SANDWICH_CACHE_TIME_OUT_DAYS = 7;

export const EMPTY_SANDWICH = {
  name: '',
  ingredients: [],
  comment: '',
};

export const MAX_INGREDIENTS_COUNT = 10;

export const MAX_NAME_LENGTH = 25;
export const MAX_COMMENT_LENGTH = 75;
